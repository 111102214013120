import React, { useEffect } from "react";
import { getLanguage } from "../utils/Localization";
import { Spinner } from "../components/spinner/Spinner";
import { navigate } from "gatsby";
import "./index.scss";

export const Index = () => {
  useEffect(() => {
    navigate(`/${getLanguage(true)}`);
  }, []);

  return (
    <div className="loader">
      <Spinner />
    </div>
  );
};

export default Index;
