import { useMatch } from "@gatsbyjs/reach-router";

export enum Country {
  SWEDEN = "SE",
  UNITED_KINGDOM = "GB",
}

export enum Language {
  SWEDISH = "sv",
  ENGLISH = "en",
}

export function isLanguage(value: string): value is Language {
  return Object.values(Language).includes(value as Language);
}

export function getLanguage(skipHook: boolean = false): Language {
  if (!skipHook) {
    const match = useMatch("/:language/*");
    if (match && match.language && isLanguage(match.language)) {
      return match.language;
    }
  }

  const defaultBrowserLanguage =
    typeof navigator !== "undefined" && navigator.language.split("-")[0];
  if (defaultBrowserLanguage && isLanguage(defaultBrowserLanguage)) {
    return defaultBrowserLanguage;
  }

  return Language.ENGLISH;
}

interface LinkProps {
  search?: string | { [key: string]: string | number };
}

export function getNavLink(path: string, options?: LinkProps): string {
  let search: string;

  if (options?.search) {
    if (typeof options.search === "object") {
      search = `?${new URLSearchParams(
        Object.entries(options.search.reduce),
      ).toString()}`;
    } else {
      search = options.search.startsWith("?")
        ? options.search
        : `?${options.search}`;
    }
  }

  const language = window.location.pathname.split("/")[1];

  return `/${language}${path}${search ?? ""}`;
}
