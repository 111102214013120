import { CSSProperties, DOMAttributes } from "react";
import cx from "classnames";

export function baseStyles(
  { className, ...props }: BaseProps,
  ...classNames
): BaseProps {
  return {
    className: cx(className, ...classNames),
    ...props,
  };
}

export interface BaseProps<T = {}> extends DOMAttributes<T> {
  className?: string | undefined;
  style?: CSSProperties | undefined;
}
